





















import { Component } from 'vue-property-decorator'
import SlideCarousel from '@/components/SlideCarousel.vue'
import SlideBasicCarouselItem from '@/views/SlideBasicCarouselItem.vue'
import { resolveSlug } from '@/utils/alias-resolver'
import { mixins } from 'vue-class-component'
import Slide from '@/mixins/Slide'

@Component({
    components: {
        SlideCarousel,
        SlideBasicCarouselItem
    }
})
export default class SlideBasicCarousel extends mixins(Slide) {
    get walkerItem() {
        return this.walker.item as SlideWalkerItemBasicCarousel
    }

    resolveWalkerSlug(walker: SlideWalker | PresentationWalker) {
        return resolveSlug(walker)
    }

    formatIndex(index: number): string {
        return index < 10 ? '0' + (index + 1) : '' + (index + 1)
    }

    zIndex(index: number) {
        return this.walker.children.length - index
    }

    goToSlide(index: number): void {
        this.$emit('go-to-slide', index)
    }
}
